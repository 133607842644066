<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="form" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：出仓单</strong>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark"
                                            :content="`创建日期:${formData.creat_date_warehouse_bill}`" placement="bottom-end" :open-delay = "750">
                                    <el-form-item label="单号:">
                                        <el-input @keyup.native.enter="$_focusNext('only_bill_date')" v-model="formData.bill_id"
                                                  :readonly="true" placeholder="自动生成" size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="日期:">
                                    <el-date-picker
                                            ref="only_bill_date"
                                            v-model="formData.only_bill_date"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            style="width:170px"
                                            @blur="$_focusNext('handled_by')"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="经手人:">
                                    <el-select ref="handled_by" v-model="formData.handled_by" clearable placeholder="可搜索查询"
                                               size="mini"
                                               default-first-option
                                               :filter-method="$_handledByListFilter"
                                               @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('bill_type')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getHandledByList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" @keyup.native.enter="$_focusNext('last_date_warehouse_bill')"
                                              v-model="formData.registrar" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_warehouse_bill"
                                              @keyup.native.enter="$_focusNext('last_name_warehouse_bill')"
                                              v-model="formData.last_date_warehouse_bill" placeholder="自动生成"
                                              :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_warehouse_bill" @keyup.native.enter="$_focusNext('bill_type')"
                                              v-model="formData.last_name_warehouse_bill" placeholder="自动生成"
                                              :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="出仓类型:">
                                    <el-select ref="billType" @keyup.native.enter="$_focusNext('remark_warehouse_bill')"
                                               v-model="formData.bill_type" default-first-option remote
                                               :remote-method="searchBillTypeList" filterable size="mini">
                                        <el-option
                                                v-for="item in billTypeList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="备注 :">
                                    <el-input ref="remark_warehouse_bill"
                                              @keyup.native.enter="$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"
                                              v-model="formData.remark_warehouse_bill" placeholder="请输入"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计数量:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总理论重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总过磅重:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_weighing_heavy"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="5" :md="12">
                                <vxe-toolbar class="pull-left" custom export ref="xToolbar2">
                                </vxe-toolbar>
                                <el-button style=" margin-top: 12px; margin-left: 12px;" type="success"
                                           round @click="$router.push('/outWarehouse')" size="mini">新建
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-left: 12px;margin-top: 12px">返回
                                </el-button>

                                <el-button style=" margin-left: 12px;margin-top: 12px; " type="danger" plain round
                                           :disabled="this.formData.is_cancel"
                                           @click="onSubmit" size="mini">保存
                                </el-button>
                            </el-col>
                        </el-row>

                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <MnGrid ref="xGrid" :height="(this.getViewHeight() - 220)+''" :table-column="tableColumn" :table-data-main="tableDataMain"
                                :form-data="formData" :allow-create="false" :search-all="true"></MnGrid>
                    </el-col>
                </el-row>


            </el-form>
        </el-main>
    </div>
</template>

<script>

    import MnGrid from "../../../components/MnGrid";

    export default {
        components: {MnGrid},
        name: "OutWarehouse",
        data() {
            return {
                isAddBill: true,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq', title: '序号', className: 'unEditor', width: 40},
                    {
                        field:'specification',
                        width: 150, title: '实际规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'category',
                        width: 120,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },

                    {
                        field:'unit', width: 45, title: '单位', slots: {edit: 'unit_edit'},
                        editRender: {
                            autofocus: 'input.el-input__inner',
                       }
                   },
                    /*{
                        field:'single_count', title: '支数',
                        slots: {edit: 'single_count_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },*/
                    {
                        field:'single_count', width: 55, title: '支数',
                        slots: {edit: 'single_count_autocomplete_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'warehouse_out',
                        width: 65,
                        title: '调出仓库',
                        slots: {edit: 'warehouse_out_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{ field:'single_count', width: 180 , title: '支数',
                        editRender: { name: 'ElInputNumber',props: {controls:false},
                            events: { change: this.singleCountEvent}
                       }
                   },*/
                    {
                        field:'weighing_heavy', title: '过磅重(吨)',
                        titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                        slots: {edit: 'weighing_heavy_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{ field:'weighing_heavy',
                        titleHelp: { message: '过磅重=理论支重*支数\n可以自行修改'},
                        title: '过磅重(吨)',
                        editRender: { name: 'ElInputNumber',props: {controls:false},
                            events: { change: this.weighingHeavyEvent}
                       }
                   },*/
                    {field:'remark_stock', title: '产品备注', editRender: {name: 'input', props: {controls: false}}},
                    {
                        field:'the_theory_of_count',
                        className: 'unEditor',
                        titleHelp: {message: '理论支数=过磅重 ÷ 每支理论重量'},
                        title: '理论支数'
                   },
                    {field:'total_count', width: 50, className: 'unEditor', title: '库存'},
                    {field:'warehouse4', width: 50, className: 'unEditor', title: '厂一'},
                    {field:'warehouse5', width: 50, className: 'unEditor', title: '厂二'},
                    {field:'warehouse1', width: 50, className: 'unEditor', title: '仓一'},
                    {field:'warehouse2', width: 50, className: 'unEditor', title: '仓二'},
                    {field:'warehouse3', width: 50, className: 'unEditor', title: '仓三'},
                    {field:'warehouse9', width: 50, className: 'unEditor', title: '仓四'},
                    {field:'warehouse7', width: 50, className: 'unEditor', title: '新门市'},
                    {
                        field:'warehouse_out_id',visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调出仓库Id'
                   },
                    {field:'single_weight', className: 'unEditor', titleHelp: {message: '支重=过磅重/支数'}, title: '支重(kg)'},
                    {
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },
                    {
                        field:'theoretical_weight_single',
                        className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n'},
                        title: '理论支重(吨)'
                   },
                ],
                tableDataMain: [],
                tableData: [],
                formData: {
                    creat_date_warehouse_bill : '',
                    only_bill_date: '',
                    bill_id: '',
                    handled_by: '',
                    registrar: '',
                    last_date_warehouse_bill: '',
                    last_name_warehouse_bill: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    remark_warehouse_bill: '',
                    total_number: '',
                    total_theoretical_weight: '',
                    total_amount: '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//税金
                    bill_type: '',
                    single_weight : '',
                    bill_id_type: 'CC',
                    is_cancel: false
               },
                unitList: [],
                accountList: [],
                categoryList: [],
                billTypeList: [],
                firstOpen: true,
           }
       },
        methods: {
            searchWarehouseStock(query) {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseOutBill/searchWarehouseStock',
                    params: {
                        keyword: query
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    loading.close();
                    if (response.status == 200) {
                        this.specification = response.data.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            //查询进仓单类型
            async searchBillTypeList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseOutNote',
                    params: {
                        keyword: query
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200) {
                        this.billTypeList = response.data.page.list
                        if (this.firstOpen && (this.formData.bill_id == null || this.formData.bill_id == '')) {
                            this.formData.bill_type = this.billTypeList[0].name;
                            this.firstOpen = false;
                       }

                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseOutBill/search',
                    params: {
                        bill_id: this.formData.bill_id,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.showCustomerEdit = false;
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            //保存出库单
            onSubmit() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let url = '';
                if (this.isAddBill) {
                    url = '/admin/warehouseOutBill/saveVue';
               } else {
                    url = '/admin/warehouseOutBill/updateVue';
               }
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.showCustomerEdit = false;
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.$router.push({path: "/outWarehouse", query: {bill_id: this.formData.bill_id}})
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });
           }
       },
        created() {
            //初始化库存搜索
            this.$_searchWarehouseStock('');
            //this.$_searchBillTypeList('');
            this.$_searchCategoryList('');
            this.$_searchUnitList('');
            this.$_searchAccountList('');
            this.searchBillTypeList();
            this.formData.only_bill_date = new Date();
            //出库单单号
            this.formData.bill_id = this.$route.query.bill_id;
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name;
           }
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                var list = [];
                this.tableDataMain = this.$_createRowData(list);
           } else {
                this.searchWarehouseBill();
           }
            this.tableDataMain = list;
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    /*    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend{
            width: 80px !important;
       }*/
    /*    .vxe-table--render-default .vxe-cell{
            padding-left:initial !important;
            padding-right: initial !important;
       }
        .el-date-editor.el-input, .el-date-editor.el-input__inner{
            width: 128px !important;
       }
        .el-form-item__content .el-input-group{
            vertical-align: initial;
       }*/

</style>
